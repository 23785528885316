<template>
	<div class="pages-service">

		<!-- <div class="the-banner"></div> -->
		<img style="width: 100%;" src="https://dh123.hndh6666.com/images/temp3_top.png" />
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">智能硬件</span>
						<span class="title-en">LEARNING PRODUCTS</span>
					</div>
				</div>
			</el-col>
			
			<div class="module1-conts">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
							<div class="module1-item wow slideInRight" style="">
								<div class="miBox2">
									<img style="" src="https://dh123.hndh6666.com/images/temp3_02.png" />
								</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 14 }">
							<div class="core-css01 slideInRight" >
								<div class="core-txt">
									<div class="title">QM90</div>
									<div class="line-group">
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
									<div class="text">新一代收款全能王</div>
									<li class="text1" style="margin-top: 15px;">支持刷卡、插卡、挥卡、聚合主扫、被扫支付</li>
									<li class="text1">支持花呗被扫分期，提升营业额</li>
									<li class="text1">人体工学扫码窗设计，支付体验佳</li>
								</div>
							</div>
						</el-col>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
							<div class="module1-item wow slideInLeft" style="">
								<div class="miBox2">
									<img style="" src="https://dh123.hndh6666.com/images/temp3_03.png" />
								</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 14 }">
							<div class="core-css01 slideInLeft" >
								<div class="core-txt">
									<div class="title">QM800</div>
									<div class="line-group">
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
									<div class="text">支分体设计，适用多种支付场景</div>
									<li class="text1" style="margin-top: 15px;">支持聚合主扫、被扫、非接支付</li>
									<li class="text1">支持4G，交易速度更快</li>
									<li class="text1">大容量电池，移动收款超便捷</li>
								</div>
							</div>
						</el-col>
					</el-col>
					
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
							<div class="module1-item wow slideInRight" style="">
								<div class="miBox2">
									<img style="" src="https://dh123.hndh6666.com/images/temp3_04.png" />
								</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 14 }">
							<div class="core-css01 slideInRight" >
								<div class="core-txt">
									<div class="title">QR68</div>
									<div class="line-group">
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
									<div class="text">扫码支付不限额，收款速度更快</div>
									<li class="text1" style="margin-top: 15px;">支持聚合主扫、被扫支付、手挥支付</li>
									<li class="text1">续航持久，满电可收款800笔</li>
									<li class="text1">防水防污。适用多种复杂环境</li>
								</div>
							</div>
						</el-col>
					</el-col>
					
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
							<div class="module1-item wow slideInLeft" style="">
								<div class="miBox2">
									<img style="" src="https://dh123.hndh6666.com/images/temp3_05.png" />
								</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 14 }">
							<div class="core-css01 slideInLeft" >
								<div class="core-txt">
									<div class="title">立牌云喇叭</div>
									<div class="line-group">
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
									<div class="text">付款码台卡&云喇叭二合一</div>
									<li class="text1" style="margin-top: 15px;">双通讯无感切换，收款顺畅稳定</li>
									<li class="text1">超大音量语音播报，防漏单</li>
									<li class="text1">产品轻便，移动收款更方便</li>
								</div>
							</div>
						</el-col>
					</el-col>
					
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:8 }">
							<div class="module1-item wow slideInLeft" style="">
								<div class="miBox2">
									<img style="" src="https://dh123.hndh6666.com/images/temp3_01.png" />
								</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 12 }" :lg="{ span: 14 }">
							<div class="core-css01 slideInLeft">
								<div class="core-txt">
									<div class="title">智能pos机 A8</div>
									<div class="line-group">
										<div class="line1"></div>
										<div class="line2"></div>
									</div>
									<div class="text">营销功能丰富，能装各种APP</div>
									<li class="text1" style="margin-top: 15px;">支持刷卡、非接、聚合主扫、被扫支付</li>
									<li class="text1">安卓系统，安装APP可实现外卖、会员、核券等功能</li>
									<li class="text1">支持扫码预授权、电子发票等功能</li>
								</div>
							</div>
						</el-col>
					</el-col>
					
				</el-col>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {};
		}
	};
</script>

<style lang="scss" scoped>
	.imgv {
		width: 350px;
		height: 196px;
	}

	.mgt-50 {
		margin-top: 50px;
	}

	.the-banner {
		height: 400px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.bgbox-white {
		padding: 50px 0;
		overflow: hidden;
		background: #fff;
		box-sizing: border-box;
	}

	.tempbox {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.module-title {
		text-align: center;
		padding-bottom: 40px;

		.title-cn {
			display: block;
			font-size: 30px;
			font-weight: bold;
			color: #1f1f1f;
		}

		.title-en {
			display: block;
			font-size: 12px;
			font-weight: 400;
			color: #757575;
			line-height: 20px;
		}
	}

	.article {
		margin-top: 20px;
		font-size: 16px;
		color: #333;
	}

	.btn-box {
		margin-top: 10px;
	}
	
	.core-css01{
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		
		.core-txt{
			display: flex;
			flex-direction: column;
		}
		
		.title{
			color: #060404;
			font-size: 20px;
			font-weight: 700;
		}
		
		.line-group{
			position: relative;
		
			.line1{
				width: 300px;
				height: 1px;
				background-color: #919191;
			}
			
			.line2{
				position: absolute;
				width: 50px;
				height: 3px;
				background-color: #DE633B;
				top: -1px;
			}
		}
		
		.text{
			color: #231815;
			font-size: 14px;
		}
		
		.text1{
			margin-top: 5px;
			color: #231815;
			font-size: 11px;
		}
	}

	.module1-conts {
		
		.module-view {
			overflow: hidden;

			.item-box {
				max-width: 400px;
				margin: 0 auto;
				padding: 10px;
				box-sizing: border-box;

				.item {
					border: 1px solid #e6e6e6;
					height: 200px;
					position: relative;

					.conts {
						position: absolute;
						height: 56px;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						img {
							display: block;
							float: left;
						}

						p {
							font-size: 18px;
							color: #1f1f1f;
							white-space: nowrap;
							line-height: 28px;
							padding-left: 70px;
							box-sizing: border-box;

							a {
								color: #1f1f1f;

								&:hover {
									color: #0066cc;
									border-bottom: 1px solid #0066cc;
								}
							}
						}
					}
				}
			}
		}

		.title {
			height: 60px;
			line-height: 60px;
			font-size: 18px;
			color: #1f1f1f;
			font-weight: bold;
		}

		.contact-box {
			width: 100%;
			overflow: hidden;

			.item-box {
				max-width: 400px;
				margin: 0 auto;
				padding: 10px;
				box-sizing: border-box;

				.item {
					border: 1px solid #e6e6e6;
					height: 140px;
					position: relative;

					.conts {
						width: 300px;
						height: 90px;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						padding: 0 10px;
						box-sizing: border-box;

						.name {
							width: 80px;
							height: 100%;
							float: left;
							text-align: center;

							p {
								font-size: 14px;
								color: #1f1f1f;
							}
						}

						.style {
							width: 100px;
							height: 100%;
							float: left;
							padding-top: 15px;
							text-align: center;
							box-sizing: border-box;

							p {
								line-height: 30px;

								img {
									width: 80px;
								}
							}
						}

						.code {
							width: 90px;
							height: 100%;
							float: right;
							border: 1px solid #e6e6e6;
							box-sizing: border-box;

							img {
								display: block;
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:1200px) and (max-width:1550px) {
		.module1-conts {
			.module-view {
				.item-box {
					.item {
						.conts {
							img {
								display: none;
							}

							p {
								padding-left: 0;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}

	.module2-conts {
		.header-box {
			width: 100%;
			height: 230px;
			background-position: center center;

			.module-title {
				padding-top: 50px;
				padding-bottom: 20px;

				.title-cn {
					color: #fff;
				}

				.title-en {
					color: #fff;
				}
			}

			.info-box {
				width: 300px;
				height: 40px;
				background: #fff;
				border-radius: 40px;
				margin: 0 auto;
				padding-top: 8px;
				box-sizing: border-box;

				.item {
					width: 100px;
					height: 24px;
					float: left;
					padding-left: 36px;
					box-sizing: border-box;
					font-size: 14px;
					color: #1f1f1f;
					line-height: 24px;
					background-size: 24px;
					background-position: 6px center;
				}
			}
		}

		.conts-box {
			width: 100%;

			.text-item {
				margin-bottom: 60px;

				h3 {
					font-size: 22px;
					color: #1f1f1f;
					margin-bottom: 25px;
					line-height: 30px;
				}

				.line1 {
					font-size: 16px;
					color: #4c4c4c;
					margin-bottom: 10px;
					line-height: 30px;
				}

				.line2 {
					font-size: 14px;
					color: #787878;
				}

				.line3 {
					line-height: 24px;
					color: #0066cc;
					padding-left: 10px;
					margin-bottom: 10px;
					box-sizing: border-box;

					img {
						width: 24px;
						height: 24px;
						margin-right: 10px;
					}
				}

				.tempbox {
					width: 400px;
					height: 300px;
				}

				.img-box {
					overflow: hidden;
					padding: 40px 0;

					img {
						width: 100%;
						height: 100%;
						display: block;
						margin: 0 auto;

						&.img-8 {
							width: 100%;
							max-width: 730px;
						}
					}

					.img-info {
						font-size: 14px;
						color: #0066cc;
						text-align: center;
					}
				}

				.table-box {
					width: 100%;
					overflow-x: scroll;
					padding-top: 40px;
					padding-bottom: 5px;
					box-sizing: border-box;

					table {
						width: 100%;
						border-right: 1px solid #333;
						border-bottom: 1px solid #333;
						border-spacing: 0;
						border-collapse: collapse;
						box-sizing: border-box;

						th {
							font-weight: 700;
							min-width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							border-left: 1px solid #333;
							border-top: 1px solid #333;
							height: 30px;
							background: #fff;
							font-size: 16px;
							color: #333;
							text-align: center;
							box-sizing: border-box;
							padding: 5px !important;
						}

						td {
							font-weight: 400;
							overflow: hidden;
							text-overflow: ellipsis;
							border-left: 1px solid #333;
							border-top: 1px solid #333;
							height: 30px;
							background: #fff;
							font-size: 14px;
							color: #333;
							text-align: center;
							box-sizing: border-box;
							padding: 5px !important;
						}
					}

					&::-webkit-scrollbar {
						width: 6px;
						height: 6px
					}

					&::-webkit-scrollbar-thumb {
						background-color: #999;
						border-radius: 6px
					}
				}
			}
		}
	}


	// 移动端css
	@media screen and (max-width: 767px) {
		.the-banner {
			height: 260px;
		}

		.module1-conts {
			.module-view {
				.item-box {
					padding: 10px 0;
				}
			}

			.contact-box {
				.item-box {
					padding: 10px 0;
				}
			}
		}
		
		.core-css01{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
</style>
